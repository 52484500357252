<template>
  <q-layout>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('conversion_report') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('conversion_report')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <q-page padding>
      <AffiliateHeaderConversionReports />
      <AffiliateListConversionReports />
    </q-page>
  </q-layout>
</template>

<script>
import AffiliateHeaderConversionReports from '@/components/reports/affiliate/AffiliateHeaderConversionReports';
import ReportMixin from '@/mixins/ReportMixin';
import AffiliateListConversionReports from '@/components/reports/affiliate/AffiliateListConversionReports';

export default {
  name: 'AffiliateConversionReports',
  components: {
    AffiliateHeaderConversionReports,
    AffiliateListConversionReports
  },
  mixins: [ReportMixin]
};
</script>
