var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-toolbar',{staticClass:"q-pr-none q-mb-md justify-end"},[_c('q-btn',{staticClass:"q-px-sm q-mx-sm",attrs:{"outline":"","icon":"mdi-filter-outline","color":"primary","no-caps":"","label":"Filtrar"},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.$t('filter_report')))])],1)],1),(_vm.filter)?_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-btn',{staticClass:"q-ml-auto",attrs:{"flat":"","round":"","dense":"","icon":"mdi-close"},on:{"click":function($event){_vm.filter = false}}})],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":_vm.$t('request_number')},model:{value:(_vm.params.orderId),callback:function ($$v) {_vm.$set(_vm.params, "orderId", $$v)},expression:"params.orderId"}})],1),_c('div',{staticClass:"col-3"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":"ID da conversão"},model:{value:(_vm.params.actionId),callback:function ($$v) {_vm.$set(_vm.params, "actionId", $$v)},expression:"params.actionId"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{attrs:{"outlined":"","type":"text","options":[
            { label: 'CPA', value: 'cpa' },
            { label: 'CPL', value: 'cpl' }
          ],"option-label":"label","option-value":"value","label":_vm.$t('type')},model:{value:(_vm.params.commissionType),callback:function ($$v) {_vm.$set(_vm.params, "commissionType", $$v)},expression:"params.commissionType"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.channelsFiltered,"label":"Canal","option-label":"name","option-value":"id","outlined":"","clearable":""},on:{"filter":_vm.filterComboChannels},model:{value:(_vm.params.siteId),callback:function ($$v) {_vm.$set(_vm.params, "siteId", $$v)},expression:"params.siteId"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"use-input":"","input-debounce":"200","options":_vm.campaignsFiltered,"label":_vm.$t('campaign'),"option-label":"campaign_name","option-value":"campaign_id","outlined":"","clearable":""},on:{"filter":_vm.filterComboCampaigns},model:{value:(_vm.params.campaignId),callback:function ($$v) {_vm.$set(_vm.params, "campaignId", $$v)},expression:"params.campaignId"}})],1),_c('div',{staticClass:"col-6"},[_c('date-input',{attrs:{"buttons":"","outlined":"","label":_vm.$t('conversion_date'),"v-model":_vm.params.date,"clearable":"","range":"","today-btn":""},on:{"update":(value) => (_vm.params.date = value)}})],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col"},[_c('q-select',{attrs:{"options":[
                {
                  label: _vm.$t('approved'),
                  value: 2
                },
                {
                  label: _vm.$t('pending'),
                  value: 1
                },
                {
                  label: _vm.$t('refused'),
                  value: 0
                }
              ],"label":_vm.$t('conversion_status'),"outlined":""},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}})],1)])]),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":_vm.$t('clear'),"no-caps":"","outline":""},on:{"click":_vm.clearFilter}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }